<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="energyModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="energyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document" style="max-width: 80%">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="energyModalLabel">Energy data for transaction id: {{ transactionId }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="energyRecordsStatus === 'loading'">
            <h6 class="font-weight-bold">Loading data... please wait...</h6>
          </div>
          <div v-else-if="energyRecordsStatus === 'error'">
            <h6 class="font-weight-bold text-danger">Error loading data</h6>
            <p class="text-sm">{{ energyRecordsError }}</p>
          </div>
          <div v-else-if="energyRecords.length === 0">
            <h6 class="font-weight-bold text-danger">No data found</h6>
          </div>
          <div v-else>
            <Line id="my-chart-id" :options="chartOptions" :data="chartData" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="chargingModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="chargingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="chargingModalLabel">Charging records for transaction id: {{ transactionId }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="chargingRecordsStatus === 'loading'">
            <h6 class="font-weight-bold">Loading data... please wait...</h6>
          </div>
          <div v-else-if="chargingRecordsStatus === 'error'">
            <h6 class="font-weight-bold text-danger">Error loading data</h6>
            <p class="text-sm">{{ chargingRecordsError }}</p>
          </div>
          <div v-else-if="chargingRecords.length === 0">
            <h6 class="font-weight-bold text-danger">No data found</h6>
          </div>
          <div v-else>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-center">Start time</th>
                  <th scope="col" class="text-center">Stop time</th>
                  <th scope="col" class="text-right">Charging duration</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in chargingRecords">
                  <td class="text-center">{{ row.timestamp_start }}</td>
                  <td class="text-center">{{ row.timestamp_end }}</td>
                  <td class="text-right">{{ getSessionDuration(row.timestamp_start, row.timestamp_end) }}</td>
                </tr>
                <tr>
                  <th scope="row" colspan="2" class="text-right">Total charging duration:</th>
                  <th scope="row" class="text-right">{{ totalTransactionDuration }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="canViewPaymentData"
    class="modal fade"
    id="paymentModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="paymentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="paymentModalLabel">Payment record for transaction id {{ transactionId }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped">
            <tr>
              <th>Payment record Id</th>
              <td>{{ paymentRecord.id }}</td>
            </tr>
            <tr>
              <th>Rfid used</th>
              <td>{{ paymentRecord.rfid_used }}</td>
            </tr>
            <tr>
              <th>Payment plan</th>
              <td>{{ paymentRecord.payment_plan }}</td>
            </tr>
            <tr>
              <th>Customer Id</th>
              <td>{{ paymentRecord.customer_id }}</td>
            </tr>
            <tr>
              <th>Customer group Id</th>
              <td>{{ paymentRecord.customer_group_id }}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{{ paymentRecord.status }}</td>
            </tr>
            <tr>
              <th>Calculation type</th>
              <td>{{ paymentRecord.calculation_type }}</td>
            </tr>
            <tr>
              <th>Full value</th>
              <td>{{ paymentRecord.full_value }}</td>
            </tr>
            <tr>
              <th>Discount</th>
              <td>{{ paymentRecord.discount }}</td>
            </tr>
            <tr>
              <th>Final value</th>
              <td>{{ paymentRecord.final_value }}</td>
            </tr>
            <tr>
              <th>Billed value</th>
              <td>{{ paymentRecord.billed_value }}</td>
            </tr>
            <tr>
              <th>Charged value</th>
              <td>{{ paymentRecord.charged_value }}</td>
            </tr>
            <tr>
              <th>Is free</th>
              <td>{{ paymentRecord.is_free }}</td>
            </tr>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-1 form-inline">
    <div class="form-group ml-auto">
      <select v-model="connector" class="form-control">
        <option value="0">All connectors</option>
        <option v-for="conn in connectors" :value="conn">
          {{ 'Connector ' + conn }}
        </option>
      </select>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr class="teal">
          <th scope="col">Transaction ID</th>
          <th scope="col">Connector ID</th>
          <th scope="col">RFID tag</th>
          <th scope="col">RFID name</th>
          <th scope="col">Virtual RFID</th>
          <th scope="col">Start Type</th>
          <th scope="col" class="text-center">Meter start (Wh)</th>
          <th scope="col" class="text-center">Meter stop (Wh)</th>
          <th scope="col" class="text-center">Energy (kWh)</th>
          <th scope="col">Timestamp start</th>
          <th scope="col">Timestamp stop</th>
          <th scope="col">Session duration (min)</th>
          <th scope="col" class="text-center">Gross amount</th>
          <th scope="col" class="text-center">Net amount</th>
          <th scope="col" v-if="canViewPaymentData">Customer</th>
          <th scope="col" v-if="canViewPaymentData">Payment type</th>
          <th scope="col">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in filteredData">
          <td
            class="align-middle"
            style="cursor: pointer"
            data-toggle="modal"
            data-target="#paymentModal"
            @click="showPaymentRecord(row.id, row.payment_record)"
          >
            {{ row.id }}<span v-if="canViewPaymentData" class="text-primary">&nbsp;&#9432;</span>
          </td>
          <td class="align-middle">{{ row.connector_id }}</td>
          <td class="align-middle">{{ row.id_tag + getAdditionalIdTagInfo(row) }}</td>
          <td class="align-middle text-center">
            {{ row.rfid_connection && !row.rfid_connection.is_virtual ? row.rfid_connection.name : '---' }}
          </td>
          <td class="align-middle text-center">
            {{ row.rfid_connection ? (row.rfid_connection.is_virtual ? 'Yes' : 'No') : 'Yes' }}
          </td>
          <td class="align-middle">{{ row.start_type ?? 'Unknown' }}</td>
          <td class="align-middle text-center">{{ row.meter_start }}</td>

          <td class="align-middle text-center">
            {{
              row.stop_billing && row.meter_stop === row.meter_start
                ? 'Billing Stopped Manually'
                : row.meter_stop == null
                  ? '---'
                  : row.meter_stop
            }}
          </td>
          <td
            class="align-middle text-center"
            style="cursor: pointer"
            data-toggle="modal"
            data-target="#energyModal"
            @click="showEnergyRecords(row.id)"
            v-html="calculateEnergyKwh(row.meter_start, row.meter_stop, row.stop_billing)"
          ></td>
          <td class="align-middle">{{ row.timestamp_start ?? '---' }}</td>
          <td class="align-middle">{{ row.timestamp_end ?? '---' }}</td>
          <!-- Charging transactions have timestamp_start and timestamp_stop -->
          <td
            class="align-middle text-center"
            style="cursor: pointer"
            data-toggle="modal"
            data-target="#chargingModal"
            @click="showChargingRecords(row.id)"
            v-html="getSessionDuration(row.timestamp_start, row.timestamp_end, true)"
          ></td>
          <td class="align-middle text-center">
            {{
              (+row.payment_record.billed_value).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}
          </td>
          <td class="align-middle text-center">
            {{
              (row.payment_record.billed_value - row.payment_record.vat_value).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}
          </td>
          <td class="align-middle" v-if="canViewPaymentData">
            <template v-if="row.customer_url"
              ><a :href="row.customer_url">{{ getOwner(row) }}</a></template
            >
            <template v-else>{{ getOwner(row) }}</template>
          </td>
          <td class="align-middle" v-if="canViewPaymentData">{{ row.payment_record.payment_plan }}</td>
          <td class="align-middle">{{ row.description }}</td>
        </tr>
      </tbody>
    </table>
    <h6 v-if="!data.length && status !== 'loading'" class="text-danger font-weight-bold">No data found</h6>
    <h6 v-else-if="!filteredData.length && status !== 'loading'" class="text-danger font-weight-bold">
      No data found for selected filters
    </h6>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement);

export default {
  props: ['data', 'connectors', 'canViewPaymentData', 'status', 'timezone'],
  components: { Line },
  data: function () {
    return {
      connector: '0',
      paymentRecord: 'test',
      chargingRecords: [],
      chargingRecordsStatus: '',
      chargingRecordsError: '',
      energyRecords: [],
      energyRecordsStatus: '',
      energyRecordsError: '',
      totalTransactionDuration: 0,
      transactionId: 0,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Energy Data',
            backgroundColor: '#f87979',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  computed: {
    filteredData: function () {
      return this.data.filter((x) => this.connector === '0' || x.connector_id === +this.connector);
    },
  },
  methods: {
    /**
     * @param   meterStartWh    Meter start in Wh
     * @param   meterStopWh    Meter stop in Wh
     * @return  Dispensed energy in kWh
     */
    calculateEnergyKwh: function (meterStartWh, meterStopWh, stopBilling) {
      if (stopBilling && meterStopWh === meterStartWh) {
        return 'Billing Stopped Manually';
      }

      if (!meterStopWh) {
        return '---';
      }

      const energyKwh = (meterStopWh - meterStartWh) / 1000;

      return energyKwh.toFixed(3) + '<span class="text-primary">&nbsp;&#9432;</span>';
    },
    getAdditionalIdTagInfo(row) {
      if (row.payment_record?.temporary?.payment_transaction) {
        return ' (' + row.payment_record.temporary.payment_transaction.shopping_cart_id + ')';
      }

      return '';
    },
    getOwner: function (row) {
      const paymentRecord = row.payment_record;
      if (paymentRecord) {
        if (paymentRecord.customer_group && paymentRecord.customer_group.name) {
          return paymentRecord.customer_group.name;
        }

        if (paymentRecord.customer && paymentRecord.customer.first_name) {
          return paymentRecord.customer.first_name + ' ' + paymentRecord.customer.last_name;
        }

        if (paymentRecord.customer && paymentRecord.customer.email) {
          return paymentRecord.customer.email;
        }
      }

      if (paymentRecord.payment_plan === 'hubjet') {
        return 'Hubject';
      }

      return 'Temporary';
    },
    getSessionDuration: function (timestampStart, timestampEnd, addInfo = false) {
      if (!timestampEnd || !timestampStart) {
        return '--';
      }

      const start = new Date(timestampStart);
      const end = new Date(timestampEnd);
      const durationMillis = end - start;
      return this.convertToMinutes(durationMillis) + (addInfo ? '<span class="text-primary">&nbsp;&#9432;</span>' : '');
    },
    convertToMinutes: function (millis) {
      const MILLIS_IN_A_MINUTE = 1000 * 60;
      const durationMinutes = millis / MILLIS_IN_A_MINUTE;
      return durationMinutes.toFixed(2);
    },
    getTotalTransactionDuration: function (rows) {
      let total = 0;
      rows.forEach((row) => {
        const start = new Date(row.timestamp_start);
        const end = new Date(row.timestamp_end);
        total += end - start;
      });

      return this.convertToMinutes(total);
    },
    showChargingRecords: function (transactionId) {
      this.chargingRecordsStatus = 'loading';
      this.chargingRecords = [];

      axios
        .get('/admin/reports/transaction-charging-details-data/' + transactionId + '?timezone=' + this.timezone)
        .then((response) => {
          this.chargingRecordsStatus = 'success';
          this.chargingRecords = response.data.data;
          this.totalTransactionDuration = this.getTotalTransactionDuration(this.chargingRecords);
          this.transactionId = transactionId;
        })
        .catch((error) => {
          this.chargingRecordsStatus = 'error';
          this.chargingRecordsError = error.toJSON();
        });
    },
    showEnergyRecords: function (transactionId) {
      this.energyRecordsStatus = 'loading';
      this.energyRecords = [];

      axios
        .get('/admin/reports/transaction-energy-details-data/' + transactionId + '?timezone=' + this.timezone)
        .then((response) => {
          this.energyRecordsStatus = 'success';
          this.energyRecords = response.data.data;
          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];
          for (const energyRecord of this.energyRecords) {
            this.chartData.labels.push(energyRecord.timestamp);
            this.chartData.datasets[0].data.push(energyRecord.value);
          }

          this.transactionId = transactionId;
        })
        .catch((error) => {
          this.energyRecordsStatus = 'error';
          this.energyRecordsError = error.toJSON();
        });
    },
    showPaymentRecord: function (transactionId, paymentRecord) {
      this.transactionId = transactionId;
      this.paymentRecord = paymentRecord;
    },
  },
};
</script>
