<template>
  <div class="main-app-overlay" v-if="status === 'loading'">
    <div class="lds-dual-ring"></div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            :class="['nav-link', type === 'transactions' ? 'active' : '']"
            aria-current="page"
            href="#"
            v-text="translations.transactions"
            @click="changeType('transactions')"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="['nav-link', type === 'messages' ? 'active' : '']"
            aria-current="page"
            href="#"
            v-text="translations.messages"
            @click="changeType('messages')"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="['nav-link', type === 'heartbeats' ? 'active' : '']"
            aria-current="page"
            href="#"
            v-text="translations.heartbeats"
            @click="changeType('heartbeats')"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="['nav-link', type === 'metervalues' ? 'active' : '']"
            aria-current="page"
            href="#"
            v-text="translations.metervalues"
            @click="changeType('metervalues')"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="['nav-link', type === 'statuses' ? 'active' : '']"
            aria-current="page"
            href="#"
            v-text="translations.statuses"
            @click="changeType('statuses')"
          >
          </a>
        </li>
        <li class="ml-auto mb-1 mr-1">
          <a class="btn btn-primary" :href="backRoute" v-text="translations.back"></a>
        </li>
        <li class="mb-1">
          <a class="btn btn-secondary" :href="dashboardRoute" v-text="translations.dashboard"></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <DateReportComponent @submit-date="getData($event)" ref="dateComponent"></DateReportComponent>
    </div>
    <div class="col-10">
      <div class="card my-3 p-3">
        <div class="row">
          <div class="col-12" v-if="type === 'messages' && !error">
            <MessagesReportComponent
              :data="messages"
              :connectors="connectors"
              :status="status"
            ></MessagesReportComponent>
          </div>
          <div class="col-12" v-if="type === 'transactions' && !error">
            <TransactionsReportComponent
              :data="transactions"
              :connectors="connectors"
              :can-view-payment-data="canViewPaymentData"
              :status="status"
              :timezone="timezone"
            ></TransactionsReportComponent>
          </div>
          <div class="col-12" v-if="type === 'heartbeats' && !error">
            <HeartbeatsReportComponent :data="heartbeats" :status="status"></HeartbeatsReportComponent>
          </div>
          <div class="col-12" v-if="type === 'metervalues' && !error">
            <HeartbeatsReportComponent :data="metervalues" :status="status"></HeartbeatsReportComponent>
          </div>
          <div class="col-12" v-if="type === 'statuses' && !error">
            <StatusesReportComponent
              :data="statuses"
              :connectors="connectors"
              :status="status"
            ></StatusesReportComponent>
          </div>
          <h5 v-if="error" class="text-danger">{{ error }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeartbeatsReportComponent from './HeartbeatsReportComponent.vue';
import MessagesReportComponent from './MessagesReportComponent.vue';
import TransactionsReportComponent from './TransactionsReportComponent.vue';
import StatusesReportComponent from './StatusesReportComponent.vue';
import DateReportComponent from './DateReportComponent.vue';

export default {
  props: [
    'messagesRoute',
    'transactionsRoute',
    'heartbeatsRoute',
    'metervaluesRoute',
    'statusesRoute',
    'backRoute',
    'dashboardRoute',
    'translations',
    'connectors',
    'canViewPaymentData',
  ],
  components: {
    HeartbeatsReportComponent,
    MessagesReportComponent,
    TransactionsReportComponent,
    StatusesReportComponent,
    DateReportComponent,
  },
  data: function () {
    return {
      type: new URLSearchParams(window.location.search).has('navigation')
        ? new URLSearchParams(window.location.search).get('navigation')
        : 'transactions',
      messages: [],
      error: '',
      transactions: [],
      heartbeats: [],
      metervalues: [],
      statuses: [],
      startTime: null,
      endTime: null,
      timezone: null,
      lastLoaded: {},
      status: '',
    };
  },
  methods: {
    changeType: function (type) {
      this.type = type;
      this.error = '';
      this.getData(null);
    },
    getData: function (data) {
      if (data) {
        this.startTime = data.start_time;
        this.endTime = data.end_time;
        this.timezone = data.timezone;
      } else if (this.startTime) {
        data = {
          start_time: this.startTime,
          end_time: this.endTime,
          timezone: this.timezone,
        };
      } else {
        return;
      }

      if (this.lastLoaded[this.type]) {
        const last = this.lastLoaded[this.type];
        if (last.start_time === data.start_time && last.end_time === data.end_time && last.timezone === data.timezone) {
          this.$nextTick(() => {
            this.$refs.dateComponent.enableButton();
          });
          return;
        }
      }

      this.lastLoaded[this.type] = data;
      this.status = 'loading';
      history.pushState({}, '', '?' + new URLSearchParams(data).toString());
      axios
        .get(this[this.type + 'Route'], { params: data })
        .then((response) => {
          this[this.type] = response.data.data;
          this.error = '';
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.error = error.response.data.error;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        })
        .finally(() => {
          this.$refs.dateComponent.enableButton();
          this.status = '';
        });
    },
  },
};
</script>
